<template>
    <!-- 添加/编辑代理商 -->
    <div class="addEditAgent">
        <div class="mask"></div>
        <div class="addEditAgentCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="cancelAgentFun"></i>
            </div>
            <el-form :model="myForm" ref="myForm" :rules="rules">
                <el-form-item label="代理商名称" prop="name">
                    <el-input v-model="myForm.name" placeholder="请输入代理商名称"></el-input>
                </el-form-item>
            </el-form>
            <div class="button">
                <el-button @click="cancelAgentFun">取 消</el-button>
                <el-button type="primary" @click="deterAgentFun">提 交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'addEditAgent',
        props: {
            // 标题
            catdTitle: {
                type: String,
                default: '编辑代理商',
            },
            // 黑名单信息
            agentList: {
                type: Object,
                default() {
                    return {}
                },
            },
        },
        data() {
            return {
                enterprise: [],
                // 表单
                myForm: {
                    // 员工姓名
                    name: '',
                },
                rules: {
                    name: [
                        {
                            required: true,
                            message: '请输入员工姓名',
                            trigger: 'blur',
                        },
                    ],
                },
                // 所属单位-下拉选择
                natures: [],
                // 被选中的企业/组织id
                companyId: null,
            }
        },
        created() {
            // console.log('this.agentList: ', this.agentList)
            if (this.catdTitle === '编辑代理商') {
                this.myForm.name = this.agentList.name
            }
        },
        methods: {
            // 点击取消
            cancelAgentFun() {
                this.$emit('cancelAgent-fun')
            },
            // 点击提交
            deterAgentFun() {
                if (!this.myForm.name) {
                    this.$message({
                        message: '必填项不能为空！',
                        type: 'error',
                    })
                    return false
                }
                let url = '/v1/agent/add'
                let url2 = '/v1/agent/edit'
                let value = {
                    name: this.myForm.name,
                }
                let value2 = {
                    agentId: this.agentList.id,
                    name: this.myForm.name,
                }
                if (this.catdTitle === '添加代理商') {
                    this.addEditPost(value, url)
                } else if (this.catdTitle === '编辑代理商') {
                    this.addEditPost(value2, url2)
                }
            },
            // 添加/编辑代理商
            addEditPost(value, url) {
                this.$instance
                    .post(url, value)
                    .then(res => {
                        // console.log('res: ', res.data)
                        if (res.data.status === 200) {
                            this.$emit('deterAgent-fun')
                            this.$message({
                                message: this.catdTitle + '成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .addEditAgent {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        & ::v-deep .addEditAgentCard {
            width: 600px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .el-form {
                padding: 30px 40px 10px;
                border-top: 1px #f0f0f0 solid;
                border-bottom: 1px #f0f0f0 solid;
                .el-form-item {
                    margin-bottom: 20px;
                    .el-form-item__label {
                        width: 120px !important;
                        padding-right: 20px;
                        line-height: 32px;
                    }
                    .el-input__inner {
                        line-height: 32px !important;
                    }
                    .el-form-item__content {
                        margin-left: 120px !important;
                        line-height: 32px;
                        .el-select {
                            width: 100%;
                        }
                        input {
                            height: 32px;
                        }
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
