<template>
    <!-- 代理商管理 -->
    <div class="agent-admin">
        <div class="agentSearch">
            <div>
                <el-button
                    @click="addDoorClick"
                    class="titleButton"
                    type="primary"
                    icon="el-icon-plus"
                    >添加代理商</el-button
                >
            </div>
            <div class="titleSearch">
                <el-input
                    @keyup.enter.native="searchClick"
                    clearable
                    v-model="input"
                    placeholder="请输入代理商名称"
                ></el-input>
                <el-button
                    @click="searchClick"
                    class="titleButton"
                    type="primary"
                    icon="el-icon-search"
                    >搜索</el-button
                >
            </div>
        </div>
        <div class="agentTableC">
            <div class="companyTable">
                <el-table
                    v-loading="loading"
                    :data="deviceData.elements"
                    style="width: 100%"
                    height="100%"
                >
                    <el-table-column
                        prop="name"
                        label="代理商名称"
                        align="center"
                        min-width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="insertTime"
                        label="添加时间"
                        align="center"
                        min-width="200"
                    >
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button
                                @click="editClick(scope.row, scope.$index)"
                                type="text"
                                >编辑</el-button
                            >
                            <el-button
                                @click="deleteClick(scope.row, scope.$index)"
                                type="text"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                :current-page="currentPage"
                :page-size="deviceData.pageSize"
                @current-change="handleCurrentChange"
                background
                layout="total, prev, pager, next"
                :total="deviceData.totalRecords"
            >
            </el-pagination>
        </div>
        <!-- 弹窗 添加/编辑代理商 -->
        <addEditAgent
            v-if="addEditAgentShow"
            @cancelAgent-fun="cancelAgentFun"
            @deterAgent-fun="deterAgentFun"
            :catdTitle="catdTitle"
            :agentList="deviceData.elements[agentId]"
        ></addEditAgent>
        <!-- 弹窗 删除 -->
        <deletePop
            v-if="deletePopShow"
            @deletePop-fun="deletePopFun"
            @determineDel-fun="determineDelFun"
            :catdTitle="catdTitle"
            :companyName="deviceData.elements[agentId].name"
            :name="deleteName"
        ></deletePop>
    </div>
</template>
<script>
import addEditAgent from '../../components/addEditAgent'
import deletePop from '../../components/deletePop'

export default {
    name: 'agent-admin',
    components: { addEditAgent, deletePop },
    data() {
        return {
            loading: false,
            // 是否显示 弹窗 添加/编辑代理商
            addEditAgentShow: false,
            // 是否显示 弹窗 删除
            deletePopShow: false,
            // 搜索框 值
            input: '',
            // 拉取到的 表格数据
            deviceData: {},
            // 当前页数
            currentPage: 1,
            //列表-开始位置
            start: 1,
            // 弹窗 标题
            catdTitle: '',
            // 被选中下标
            agentId: null,
            deleteName: '的代理商信息',
        }
    },
    created() {
        this.equipmentList()
    },
    methods: {
        // 弹窗 添加/编辑代理商 取消
        cancelAgentFun() {
            this.addEditAgentShow = false
        },
        // 弹窗 添加/编辑代理商 提交
        deterAgentFun() {
            this.equipmentList()
            this.addEditAgentShow = false
        },
        // 点击 添加代理商
        addDoorClick() {
            this.catdTitle = '添加代理商'
            this.addEditAgentShow = true
        },
        // 点击 编辑
        editClick(val, index) {
            this.agentId = index
            this.catdTitle = '编辑代理商'
            this.addEditAgentShow = true
        },
        // 代理商管理 列表
        equipmentList() {
            this.loading = true

            this.$instance
                .get('/v1/agent/list', {
                    params: {
                        pageNum: this.currentPage - this.start,
                        pageSize: 10,
                        // 搜索关键字 SN
                        agentName: this.input,
                    },
                })
                .then((res) => {
                    // console.log('res: ', res.data)
                    if (res.data.status === 200) {
                        this.loading = false
                        this.deviceData = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击搜索
        searchClick() {
            this.currentPage = 1
            this.equipmentList()
        },
        // 点击分页-当前页
        handleCurrentChange(val) {
            this.currentPage = val
            // 搜索并拉取列表
            this.equipmentList()
        },
        // 点击 删除
        deleteClick(val, index) {
            this.agentId = index
            this.catdTitle = '删除代理商'
            this.deletePopShow = true
        },
        // 弹窗 删除 取消
        deletePopFun() {
            this.deletePopShow = false
        },
        // 弹窗 删除 确定
        determineDelFun() {
            // let value = {
            //     agentId: this.deviceData.elements[this.agentId].id,
            // }
            this.deletePost()
        },
        // 删除代理商
        deletePost() {
            this.$instance
                .post(
                    '/v1/agent/del?agentId=' +
                        this.deviceData.elements[this.agentId].id,
                    {}
                )
                .then((res) => {
                    // console.log('res: ', res.data)
                    if (res.data.status === 200) {
                        // 搜索并拉取列表
                        this.equipmentList()
                        this.deletePopShow = false
                        this.$message({
                            message: this.catdTitle + '成功！',
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.agent-admin {
    width: calc(100% - 40px);
    margin: 20px;
    background-color: #fff;
    // 搜索
    & ::v-deep .agentSearch {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        box-sizing: border-box;
        border-bottom: 1px #f0f0f0 solid;
        .el-button--primary {
            line-height: 32px;
            padding: 0 12px;
            border: unset;
        }
        .titleSearch {
            display: flex;
            .el-select > .el-input,
            .el-input {
                margin-right: 6px;
            }
            .el-select > .el-input {
                width: 150px;
            }
            .el-input {
                width: 240px;
                .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                }
                .el-input__suffix {
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .agentTableC {
        width: calc(100% - 40px);
        height: calc(100% - 144px);
        margin: 20px;
        // 表格
        & ::v-deep .companyTable {
            height: 100%;
            border: 1px #f0f0f0 solid;
            overflow: auto;
            .el-table td,
            .el-table th {
                padding: 4px 0;
            }
        }
        // 翻页
        .el-pagination {
            text-align: right;
            margin-top: 10px;
            padding: unset;
        }
    }
}
</style>
